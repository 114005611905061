

























import { ServiceDocumentModel } from "@/api/generated";
import { Vue, Component, Prop } from "vue-property-decorator";
import Api from "@/api";
import FileDownloadUtility from "@/utility/fileDownloadUtility";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import ServiceDocumentTable from "@/components/service/ServiceDocumentTable.vue";
import ServiceDocumentCreateDialog from "@/components/dialogs/ServiceDocumentCreateDialog.vue";
import ServiceDocumentUpdateDialog from "@/components/dialogs/ServiceDocumentUpdateDialog.vue";

const snackbarModule = getModule(SnackbarModule);

@Component({
  components: {
    ServiceDocumentTable,
    ServiceDocumentCreateDialog,
    ServiceDocumentUpdateDialog
  }
})
export default class ServiceDocuments extends Vue {
  @Prop(String) private serviceId!: string;
  private selectedServiceDocumentId: string | undefined = "";
  private refreshKey = false;
  private createDialog = false;
  private updateDialog = false;

  private getDocuments() {
    this.refreshKey = !this.refreshKey;
  }

  private onCreateDocument() {
    this.createDialog = true;
  }

  private onEditDocument(document: ServiceDocumentModel) {
    if (!document.serviceId) {
      return;
    }

    this.selectedServiceDocumentId = document.serviceDocumentId;
    this.updateDialog = true;
  }

  private async onDownloadDocument(document: ServiceDocumentModel) {
    if (!document.documentId) {
      return;
    }

    const fileResponse = await Api.DocumentService.apiDocumentDocumentIdGet(
      document.documentId
    );

    FileDownloadUtility.DownloadFile(fileResponse.data, document.filename);
  }

  private async onDeleteDocument(document: ServiceDocumentModel) {
    if (!document.serviceDocumentId) {
      return;
    }

    try {
      await Api.ServiceDocumentService.apiServicedocumentServiceDocumentIdDelete(
        document.serviceDocumentId
      );
      snackbarModule.setSnackbarMessage("Document deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete document");
    } finally {
      this.refreshKey = !this.refreshKey;
    }
  }
}
