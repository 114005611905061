










































import { DataTableHeader } from "vuetify";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import nameof from "@/utility/nameof";
import Api from "@/api";
import { ServiceDocumentModel } from "@/api/generated";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component
export default class VesselDocumentTable extends Vue {
  @Prop(String) private serviceId!: string;
  private loading = false;
  private documents: Array<ServiceDocumentModel> = [];
  private headers: Array<DataTableHeader<ServiceDocumentModel>> = [
    { text: "", value: "actions" },
    { text: "Filename", value: nameof<ServiceDocumentModel>("filename") },
    {
      text: "Reference",
      value: nameof<ServiceDocumentModel>("reference")
    },
    {
      text: "Uploaded",
      value: nameof<ServiceDocumentModel>("createdUtc")
    }
  ];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    await this.getDocuments();
  }

  @Watch("serviceId", { immediate: true })
  private async getDocuments() {
    try {
      this.loading = true;
      const response = await Api.ServiceDocumentService.apiServicedocumentServiceServiceIdGet(
        this.serviceId
      );
      this.documents = response.data;
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch documents");
    } finally {
      this.loading = false;
    }
  }
}
